import img from "../../images/other_services/pexels-andrea-piacquadio-3771087_.jpg";
import Service from "../../components/Service";
import {pageStyles} from '../../styles/style';
import Footer from "../../components/Footer";
import DownbarS from "../../components/down_footer_s";
import Navbar from "../../components/Navbar";
import Banner from "../../components/Banner";
import Works from "../../components/Works";
import { Helmet } from "react-helmet";
import * as React from "react";
import "../../styles/main.css";
import FloatButton from "../../components/FloatButton";

// data

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <FloatButton />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Manutenpul</title>
        <meta name="keywords" content="impresa di pulizie milano, impresa di pulizie milano prezzi, impresa pulizie costi milano, impresa di pulizie cesano boscone, impresa di sanificazione, impresa di pulizie lodi" />
      </Helmet>
      <Navbar></Navbar>
      <DownbarS></DownbarS>
      <Banner pageName="Portierato e Reception " prepageName="Servizio" postpageName="Reception e Portierato" />
      <Service
        title="Portierato"
        text="Hai bisogno di un servizio di portierato o di un custode? 
        Sia per imprese, sia per privati noi di Manutenpul, ti mettiamo in contatto con quello che cerchi. Selezioniamo al poso tua la figura adatta alle tue esigenze. "
        img={img}
      >
      <br />
      <br />
      <p>Potrai avere una persona una volta ogni tanto, parth time o full time. 
        Manutenpul sarà a tua completa disposizione per ogni tua esigenza specifica.
        Siamo il servizio di portineria o custode che cerchi.</p>
      </Service>
      <Works />
      <Footer></Footer>
    </main>
  );
};

export default IndexPage;
